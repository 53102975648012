import React from 'react'

function FeatureCard(props) {
  return (
    <div className="feature_card">
      <div className={props.class}>
        {props.children}
      </div>
    </div>
  )
}

function Section(props) {
  return (
    <div className={props.class}>
      {props.title &&
        <h2>{props.title}</h2>
      }
      {props.children}
    </div>
  )
}

function PortfolioItem(props) {
  return (
    <div className="portfolio_card">
      {props.children}
    </div>
  )
}

export { FeatureCard, Section, PortfolioItem }
