import React from 'react'
import { IoIosSend, IoLogoLinkedin, IoLogoGithub } from 'react-icons/io'

import Layout from '../components/layout'
import { Section } from '../components/section'

// Images
import logo from "../img/logo-white.svg"
import michele from '../img/work/michele-hodges.jpg'
import riggs from '../img/work/riggs-website.jpg'
import mtoday from '../img/work/maryland-today.jpg'
import umcpf from '../img/work/umcpfoundation.jpg'
import gl from '../img/work/carnegie-gl.jpg'
import giving from '../img/work/giving.jpg'
import umd from '../img/work/umd.jpg'
import vocanter from '../img/work/vocanter.jpg'
import search from '../img/work/search-umd.jpg'

import "../sass/styles.scss"

// Set Year
const current_date = new Date ()
const year = current_date.getFullYear()

function Home() {
  return (
    <Layout>
      <Section
        class="home_section full accent"
        >
        <div className="brand">
          <div className="logo">
            <img src={logo} alt="Michael Kamuiru" />
          </div>
          <div className="tagline">UX Enthusiast / Full Stack Web Developer</div>
          <hr/>
            <p>I am an experienced web developer / designer who is a strong advocate for the user centered design approach in all the customer’s experience touch points. I achieve this elusive goal by crafting and championing for clean and great user experiences. When I am not working behind the computer screen, I enjoy the outdoors on bicycle to stay fit.
    </p>

        <div className="contacts">
          <a href="mailto:mkamuiru@gmail.com" target="_blank" rel="noopener noreferrer">
            <span className="icons"><IoIosSend /></span>
          </a>
          <a href="https://www.linkedin.com/in/michaelkamuiru/" target="_blank" rel="noopener noreferrer">
            <span className="icons"><IoLogoLinkedin /></span>
          </a>
          <a href="https://github.com/mkamuiru" target="_blank" rel="noopener noreferrer">
            <span className="icons"><IoLogoGithub /></span>
          </a>

        </div>

        </div>
      </Section>

      <Section
        class="section mist"
        title="Work"
        >
        <p>Below are some projects I've worked on.</p>
        <br />

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={mtoday} alt="Maryland Today"/>
            </div>
            <div className="portfolio-body">
              <div className="title">Maryland Today</div>
              <p>University Wide Communications Website that reaches out 50,000+ Users and a daily email component.</p>
              <p>Utilizes a Decoupled Drupal 8 approach with React JS, Express JS, and Algolia JS search components</p>
              <p>Hosted on Netlify and AWS</p>
              <a href="https://today.umd.edu" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={umd} alt="University of Maryland"/>
            </div>
            <div className="portfolio-body">
              <div className="title">University of Maryland</div>
                <p>Drupal 7, Bootstrap, Hosted on AWS</p>
                <a href="https://umd.edu" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={riggs} alt="Riggs Alumni Center"/>
            </div>
            <div className="portfolio-body">
              <div className="title">Riggs Alumni Center</div>
                <p>Project involved gathering requirements from stakeholders, Ideation, Prototyping and development</p>
                <p>Drupal 7, Bootstrap</p>
                <a href="https://riggs.umd.edu" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={giving} alt="Giving to Maryland"/>
            </div>
            <div className="portfolio-body">
              <div className="title">Giving to Maryland</div>
              <p>Project involved gathering requirements from stakeholders, Ideation, Prototyping and frontend development</p>
              <a href="https://giving.umd.edu" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={umcpf} alt="University of Maryland College Park Foundation"/>
            </div>
            <div className="portfolio-body">
              <div className="title">University of Maryland College Park Foundation</div>
              <p>Project involved gathering requirements from stakeholders, Ideation, Prototyping and frontend development</p>
              <a href="http://umcpf.org/board/homepage.php" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={vocanter} alt="Vocanter"/>
            </div>
            <div className="portfolio-body">
              <div className="title">Vocanter LLC</div>
                <p>An agency website creation and branding built on Next JS, Bootstrap and Hosted on Netlify</p>
                <a href="https://vocanter-website.netlify.com/" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={michele} alt="Michele Hodges Events"/>
            </div>
            <div className="portfolio-body">
              <div className="title">Michele Hodges Events</div>
              <p>Event planner website creation, branding, and maintenance</p>
              <a href="https://www.michelehodgesevents.com" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={search} alt="University of Maryland Google Seach"/>
            </div>
            <div className="portfolio-body">
              <div className="title">University of Maryland Search</div>
                <p>University Wide Search website.</p>
                <p>Built on Google Custom Search with Bootstrap as the frontend UI framework</p>
                <p>Hosted on Netlify</p>
                <a href="https://search.umd.edu" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

          <div className="portfolio-card">
            <div className="portfolio-image">
              <img src={gl} alt="Geophysical Labs"/>
            </div>
            <div className="portfolio-body">
              <div className="title">Carnegie Science Geophysical Labs</div>
              <p>Project involved gathering requirements from stakeholders, Ideation, Prototyping and development</p>
              <p>Drupal 7, Bootstrap</p>
              <a href="https://gl.carnegiescience.edu/people" className="portfolio-link" target="_blank" rel="noopener noreferrer">Visit Live site</a>
            </div>
          </div>

      </Section>

      <div className="footer">Michael Kamuiru | {year}</div>
    </Layout>
  )
}


export default Home
